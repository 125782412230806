import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
	Form,
	FormGroup,
	Button,
	Row,
	Col,
	CustomInput,
	Label,
	NavLink,
	FormText,
	FormFeedback,
	Card,
	CardBody,
	UncontrolledTooltip as Tooltip
} from 'reactstrap';
import {Table, Tbody, Td, Thead, Tr} from "css-table";

import { getValidation, validate, getContent, clearMessages } from 'core/ducks/forms';
import { buildPath } from 'core/model/lib/urlTools';
import { Input, Loading } from 'core/components';
import { ErrorPage } from 'core/views/pages';
import { postData, updateData } from 'core/ducks/update';
import { pushNotification } from 'core/ducks/notifications';
import { DynamicRoutes } from '../../model/routes';
import { PolygonInput} from 'input-fields';
import { characterConverter } from 'core/model/lib';
import { DropKML, DropKMLContextConsumer } from 'dropKML';
import T from 'modules/i18n';

class AddTopic extends Component {

	constructor(props) {
		super(props);

		this.initialValues = {
			title: '',
			mname: '',
			description: '',
			has_geometry: false,
			boundary: [],
			geom_type: '',
			max_geom: '',
			geom_color: '#777777',
			project: props.match.params.project,
			relative_documents: []
		};

		this.state = {
			values: {
				...this.initialValues,
			},
			shortname: '',
			topic: props.match.params.topic ? props.match.params.topic : null,
			underSubmit: false,
			submitted: false,
			httpStatus: 200,
			pending: true,
		};
		this.emptyRelativeDocument = { title: null, file: null};

		this.fetchContent = this.fetchContent.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.handleShortnameChange = this.handleShortnameChange.bind(this);
		this.handleRelativeDocumentChange = this.handleRelativeDocumentChange.bind(this);
	}

	componentDidMount() {
		if (this.state.topic) {
			this.fetchContent(this.state.topic);
		} else {
			this.setState({pending: false});
		}
		this.props.dispatch( getValidation('node') ).catch(err => console.warn(err));
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.match.params.topic !== this.props.match.params.topic) {
			const { topic } = this.props.match.params;
			this.setState({
				topic,
			});
			this.fetchContent(topic);
		}

		if ( !prevState.underSubmit && this.state.underSubmit === 'post')
			this.uploadData('post');

		if ( !prevState.underSubmit && this.state.underSubmit === 'put')
			this.uploadData('put');

		if (!prevState.submitted && this.state.submitted)
			this.setState({
				submitted: false,
			});

		if (prevProps.validationPending && !this.props.validationPending) {
			this.initialValues = {
				...this.initialValues,
			};
			this.setState({
				values: {
					...this.initialValues
				}
			});
		}
	}

	fetchContent(topic) {
		this.props.dispatch( getContent(`node/project/${this.state.values.project}/token/${topic}`, 'topic') )
		.then(status => {
			this.initialValues = Object.keys(this.initialValues)
				.reduce((obj, key) => ({
					...obj,
					[key]: this.props.content.topic[key]
				}), {});

			this.initialValues.project = this.props.match.params.project;
			this.setState({
				values: {...this.initialValues},
				shortname: this.initialValues.mname,
				pending: false
			});
		})
		.catch(status => {
			this.setState({httpStatus: status, pending: false});
		});
	}

	uploadData(method) {
		const { values } = this.state;
		let empty = true;
		Object.keys(values).forEach(key => {
			if (this.initialValues[key] !== values[key])
				empty = false;
		});

		if (!empty || method === 'post') {
			const url = method === 'put' ? `node/token/${this.state.topic}` : 'node';
			const action = method === 'put' ? updateData : postData;
			this.props.dispatch( action(url, this.state.values) )
				.then(response => {
					this.initialValues = {
						...this.state.values,
					};
					this.setState({underSubmit: false});
					if (method === 'post' && this.props.history)
						this.props.history.push(buildPath(DynamicRoutes.TopicEdit, [values.project, response.token]));
					if (this.props.toggle)
						this.props.toggle();
				})
				.catch(error => {
					console.warn(error);
					this.setState({underSubmit: false});
				});
		} else {
			this.setState({underSubmit: false});
		}
	}

	handleChange(event) {
		let { name, type, value, checked } = event.target;
		let otherValues = {};
		if (name === 'has_geometry' && checked === false)
			otherValues = {
				geom_type: '',
				max_geom: '',
			};
		this.setState({
			values: {
				...this.state.values,
				...otherValues,
				[name]: (type === 'checkbox' || type === 'switch') ? checked : value
			},
			underSubmit: false
		});
	}

	handleShortnameChange(event) {
		const { value } = event.target;
		const { messages, dispatch } = this.props;
		if (messages.mname !== '')
			dispatch( clearMessages('mname') );
		this.setState({
			shortname: value,
			values: {
				...this.state.values,
				mname: characterConverter(value),
			}
		});
	}

	handleRelativeDocumentChange(event, index) {
		const { value, name, type } = event.target;
		const { relative_documents } = this.state.values;
		const relativeDocument = {
			...this.state.values.relative_documents[index],
			[name]: type === 'file' ? event.target.files[0] : value
		};
		this.setState(prevState => ({
			values: {
				...prevState.values,
				relative_documents: [
					...relative_documents.slice(0, index),
					relativeDocument,
					...relative_documents.slice(index + 1),
				]
			}
		}));
	}

	handleSubmit(event) {
		event.preventDefault();
		const { dispatch, rules } = this.props;
		const { values } = this.state;
		const method = this.state.topic ? 'put' : 'post';
		if (values.has_geometry && values.boundary.length === 0) {
			dispatch(pushNotification({body: 'please draw the boundary', type: 'warning'}));
		} else {
			dispatch(validate(values, rules, 'node', this.initialValues)).then(() => {
				if (this.props.valid)
					this.setState({underSubmit: method});
			});
		}
	}

	resetForm = () => {
		this.setState({
			values: {...this.initialValues},
			shortname: this.initialValues.mname,
		});
	}


	render() {

		const { values, httpStatus, pending } = this.state;
		const { rules, messages } = this.props;

		if (this.props.validationPending || this.props.validationScope !== 'node' || pending)
			return (<Loading />);

		if (httpStatus !== 200)
			return (<ErrorPage status={httpStatus} />);

		return (
			<Card className="ppcity-admin">
				<CardBody>
					<Form onSubmit={this.handleSubmit}>
						<Row>
							<Col>
								<FormGroup row>
									<Col className="py-0" sm="9" lg="10">
										<FormGroup>
											<Input
												style={{fontSize: 150+'%'}}
												name="title"
												autoComplete="off"
												value={values.title}
												onChange={this.handleChange}
												placeholder="Title"
												pattern={rules.title.validation}
												valid={messages.title === ''}
											/>
											<FormFeedback><T>{messages.title || rules.title.message}</T></FormFeedback>
										</FormGroup>
									</Col>
									<Col className="py-1" sm="3" lg="2" style={{margin: '-1.2rem 0 0 0'}}>
										<Label
											style={{opacity: this.state.shortname==='' ? 0 : 1, fontSize: "75%"}}
											className="animated fadeIn fadeOut my-0"
										>
											Short name
										</Label>
										<FormGroup>
											<Input
												style={{fontSize: 75+'%'}}
												name="shortname"
												autoComplete="off"
												value={this.state.shortname}
												onChange={this.handleShortnameChange}
												placeholder="Short name"
												className="d-inline-block"
												minLength={rules.mname.min_size}
												maxLength={rules.mname.max_size}
												valid={messages.mname === ''}
											/>
											<FormFeedback><T>{messages.mname || rules.mname.message}</T></FormFeedback>
										</FormGroup>
									</Col>
								</FormGroup>
							</Col>
						</Row>
						<Row>
							<Col>
								<FormGroup>
									<Input
										type="textarea"
										placeholder="Description"
										rows={5}
										name="description"
										value={values.description}
										onChange={this.handleChange}
										valid={messages.description === ''}
									/>
									<FormFeedback><T>{messages.description || rules.description.message}</T></FormFeedback>
								</FormGroup>
							</Col>
						</Row>
						<Row>
							<Col>
								<div className="float-right">
									<FormGroup>
										<CustomInput
											id="has_geometry_switch"
											type="switch"
											label="Has geometry?"
											name="has_geometry"
											checked={values.has_geometry}
											onChange={this.handleChange}
										/>
									</FormGroup>
									<fieldset disabled={!values.has_geometry}>
										<FormGroup className="m-1">
											<Label
												style={{opacity: values.geom_type==='' ? 0 : 1, fontSize: "75%"}}
												className="animated fadeIn fadeOut my-0"
											>
												Geometry type:
											</Label>
											<Input required type="select" name="geom_type" value={values.geom_type} onChange={this.handleChange} >
												<option value=''>Select geometry type</option>
												<option value="point">Point</option>
												<option value="line">Line</option>
												<option value="polygon">Polygon</option>
											</Input>
										</FormGroup>
										<FormGroup className="m-1">
											<Label
												style={{opacity: values.max_geom==='' ? 0 : 1, fontSize: "75%"}}
												className="animated fadeIn fadeOut my-0"
											>
												Number of geometries:
											</Label>
											<Input
												required
												placeholder="Geometries number (max)"
												type="number"
												name="max_geom"
												value={values.max_geom}
												onChange={this.handleChange}
											/>
										</FormGroup>
										<FormGroup className="m-1">
											<Label style={{fontSize: "75%"}}>Geometry color:</Label>
											<Input
												placeholder="Geometry color"
												type="color"
												name="geom_color"
												value={values.geom_color}
												onChange={this.handleChange}
											/>
										</FormGroup>
									</fieldset>
								</div>
								<FormGroup>
									<FormText>Draw the geometric boundary or drag & drop a KML file containing the boundary.</FormText>
									<DropKML>
										<DropKMLContextConsumer>
											{({kml}) =>
												<PolygonInput
													draw={{
														polygon: {showArea: true, metric: ['km', 'm'], precision: {km: 2, m: 1}},
														rectangle: true,
														marker: false,
														polyline: false,
														circlemarker: false,
														circle: false
													}}
													center={this.props.mapSettings.center || [37.983810, 23.727539]}
													zoom={this.props.mapSettings.zoom || 14}
													height={500}
													onChange={this.handleChange}
													name="boundary"
													geojson={kml || values.boundary}
													returnGeoJSON={true}
												/>
											}
										</DropKMLContextConsumer>
									</DropKML>
								</FormGroup>
							</Col>
						</Row>

						<Row>
							<Col sm="12">
								<FormGroup tag="fieldset" className="p-2">
									<Label><T>relative_documents</T></Label>
									<Table>
										<Thead>
											<Tr>
												<Td><T>title</T></Td>
												<Td>URL</Td>
												<Td/>
											</Tr>
										</Thead>
										<Tbody>
											{
												typeof this.state.values.relative_documents !== 'string' ?
													this.state.values.relative_documents.map((doc, index) => (
													<Tr  key={`relativeDocument_${index}`}>
														<Td>
															<Input required name="title" value={doc.title} onChange={(e) => this.handleRelativeDocumentChange(e, index)}/>
														</Td>
														<Td>
															<Input required name="file" value={doc.file} onChange={(e) => this.handleRelativeDocumentChange(e, index)}/>
														</Td>
														<Td>
															<i
																role="button"
																className="fa fa-trash-o"
																onClick={() => {
																	this.setState(prevState => ({
																		values: {
																			...prevState.values,
																			relative_documents: [...prevState.values.relative_documents.slice(0, index), ...prevState.values.relative_documents.slice(index + 1)]
																		}
																	}));
																}}
															/>
														</Td>
													</Tr>
												))
													: ''
											}
										</Tbody>
									</Table>
									<div className="float-right">
										<i
											role="button"
											className="fa fa-plus mr-2"
											onClick={() => {
												this.setState(prevState => ({
													values: {
														...prevState.values,
														relative_documents: [...prevState.values.relative_documents, this.emptyRelativeDocument]
													}
												}));
											}}
										/>
									</div>
								</FormGroup>
							</Col>
						</Row>

						<Row>
							<Col className="text-right">
								<Button className="mr-2" type="submit" color="primary">Add</Button>
								{ typeof this.props.toggle === 'function' ?
									<Button type="button" color="warning" onClick={this.props.toggle}>Cancel</Button>
									:
									<Button type="button" color="warning" onClick={this.resetForm}>Cancel</Button>
								}
							</Col>
						</Row>
					</Form>
				</CardBody>
			</Card>
		);
	}
}

const mapStateToProps = (state) => ({
	rules: state.forms.validation.rules,
	validationPending: state.forms.validation.pending,
	contentPending: state.forms.pending,
	content: state.forms.content,
	validationScope: state.forms.validation.scope,
	valid: state.forms.valid,
	messages: state.forms.validation_msgs,
	progress: state.upload.progress,
	mapSettings: state.ui.settings.values.map,
});

AddTopic = connect(mapStateToProps)(AddTopic);

export default AddTopic;
