import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'reactstrap';
import Chart from 'react-apexcharts'
import PredefinedStats from './predefinedStats';

import { getData } from 'core/ducks/update';
import T from 'modules/i18n';

class Stats extends Component {

	constructor(props) {
		super(props);
		this.state = {
			data: {},
			predefined: null,
			ready: false,
		};
	}

	componentDidMount() {
		let url = `data/scope/ensemble/attribute/${this.props.attribute}`;
		if (this.props.filter)
			url += `/within/${this.props.filter}`;
		this.props.dispatch(
			getData(url)
		).then(response => this.parseData(response))
		.catch(err => console.warn(err));
	}

	parseData = (data) => {
		const { count, dist, profile } = data;
		const categories = Object.keys(dist);
		const values = Object.values(dist);
		const profileCategories = [];
		const series = Object.keys(profile).map(question => ({
			name: question,
			data: [],
		}));
		Object.keys(profile).forEach(question => {
			Object.keys(profile[question]).forEach(key => {
				profileCategories.push(key);
				series.forEach(one => {
					one.data.push(question === one.name ? profile[question][key] : null);
				});
			});
		});
		this.setState({
			data: {
				count,
				dist: {categories, values},
				profile: {categories: profileCategories, series}},
				predefined: data.geometry || null,
			ready: true,
		});
	}

	render() {

		const { data, predefined, ready } = this.state;

		if (!ready) return null;

		return (
			<div className={this.props.className ? `stats ${this.props.className}` : 'stats'}>
				<Row>
					<Col>
						<p><T>answered</T>: {data.count}</p>
					</Col>
				</Row>
				<Row>
					{ data.dist.categories.length > 0 &&
						<Col>
							<T>distribution per variable</T>:
							<Chart
								series={[{name: 'frequency', data: data.dist.values}]}
								options={{
									plotOptions: {bar: {horizontal: true, barHeight: '20%'}},
									xaxis: {categories: data.dist.categories},
								}}
								type="bar"
							/>
						</Col>
					}
					<Col>
						<T>consultation profile distribution</T>
						<Chart
							series={data.profile.series}
							options={{
								plotOptions: {bar: {horizontal: true}},
								xaxis: {categories: data.profile.categories},
							}}
							type="bar"
						/>
					</Col>
				</Row>
				{ predefined &&
					<Row>
						<Col>
							<PredefinedStats geojson={predefined}/>
						</Col>
					</Row>
				}
			</div>
		);
	}
}

Stats = connect(null)(Stats);

export default Stats;
